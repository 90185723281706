import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'

const i18n = createI18n({
  locale: 'en', // default locale
  fallbackLocale: 'en', // fallback locale
  messages: {
    en,

  },
  silentFallbackWarn: true,
  silentTranslationWarn: true,
})

export default i18n