<template>
  <section class="section statistics-section">
    <div class="wrapper">
      <StatBlock
        :label="$t(`Vast collection`)"
        :value="
          $t(`We offer a wide selection, including rare and exclusive items`)
        "
      />
      <StatBlock
        :label="$t(`100% Secure`)"
        :value="$t(`Enjoy a fulminant and reliable delivery system`)"
      />
      <StatBlock
        :label="$t(`Competitive Prices`)"
        :value="
          $t(
            `Get the best deals with our transparent and real-time pricing system`
          )
        "
      />
    </div>
  </section>
</template>

<script>
import StatBlock from "../components/StatBlock.vue";
export default {
  name: "StatSection",
  components: {
    StatBlock,
  },
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

@media (max-width: 900px) {
  .wrapper {
  }
}

@media (max-width: 768px) {
  .stat-block {
  }

  .stat-value {
    font-size: 32px;
    line-height: 40px;
  }

  .stat-label {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (max-width: 460px) {
  .wrapper {
  }
}
</style>
