<template>
  <section class="section features-section">
    <div class="wrapper">
      <h2 class="section-title title">{{ $t(`All in One Place`) }}</h2>
      <div class="cards-container">
        <div class="card">
          <div class="icon">
            <img :src="require('@/assets/img/feature-icon-left.svg')" alt="" />
          </div>
          <h3 class="card-title">
            {{
              $t(
                `Experience peace of mind with our dedicated customer support team`
              )
            }}
          </h3>
          <!-- <p class="card-subtitle">{{ $t(`Extensive Collection`) }}</p> -->
        </div>

        <div class="card">
          <div class="icon">
            <img
              :src="require('@/assets/img/feature-icon-middle.svg')"
              alt=""
            />
          </div>
          <h3 class="card-title">
            {{ $t(`Browsing has never been easier!`) }}
          </h3>
          <!-- <p class="card-subtitle">{{ $t(`Secure Transactions`) }}</p> -->
        </div>

        <div class="card">
          <div class="icon">
            <img :src="require('@/assets/img/feature-icon-right.svg')" alt="" />
          </div>
          <h3 class="card-title">
            {{ $t(`Enjoy regular discounts, promotions and giveaways`) }}
          </h3>
          <!-- <p class="card-subtitle">{{ $t(`Fast Access`) }}</p> -->
        </div>

        <div class="card full-width">
          <div class="card-title">
            {{ $t(`Improve your inventory with ChameleonSkins`) }}
          </div>
          <img
            :src="require('@/assets/img/bottom-card-image-1.png')"
            alt=""
            class="bottom-card-image"
          />
          <!-- <img :src="require('@/assets/img/bottom-card-image-2.png')" alt="" class="bottom-card-image"> -->
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.features-section {
  color: #ffffff;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(386px, 1fr));
  gap: 20px;
}

.card {
  padding: 24px;
  border-radius: 4px;
  background: #516182;
  display: flex;
  flex-direction: column;

  .icon {
    width: 24px;
    height: 24px;
    margin-bottom: 32px;
  }

  .card-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    color: #ffffff;
  }

  .card-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #d3d7dc;
  }
}

.full-width {
  grid-column: 1 / -1;
  position: relative;
  height: 196px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.full-width .card-title {
  font-size: clamp(2rem, 1.385rem + 0.962vw, 2.25rem);
  line-height: 45px;
}

.bottom-card-image {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 1024px) {
  .full-width {
    justify-content: center;
  }

  .full-width img {
    display: none;
  }
}

@media (max-width: 600px) {
  .cards-container {
    grid-template-columns: 1fr;
  }

  .full-width {
    grid-column: auto;
  }

  .bottom-card {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    .bottom-card-images {
      position: static;
      margin-top: 20px;

      .bottom-card-image {
        position: static;
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
